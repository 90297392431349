import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Nem található" />
    <h1>404: Nem található</h1>
    <p>Valószínű, hogy az URL amit megadtál, hibás.</p>
    <p>
      <Link to={'/'}>Kezdőlapra.</Link>
    </p>
  </Layout>
)

export default NotFoundPage
